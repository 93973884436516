import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'Loading',
  props: ['size'],
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_openBlock(), _createBlock(_component_v_progress_circular, {
    size: props.size ?? 50,
    color: "primary",
    indeterminate: ""
  }, null, 8, ["size"]))
}
}

})