import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { notify } from '@kyvg/vue3-notification';
import { useRouter } from 'vue-router';
import AuthService from '@/services/auth.service';

import fetchIntercept from 'fetch-intercept';
import { publicUrls } from './constants/urls';

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const router = useRouter();
fetchIntercept.register({
  response: response => {
    const status = response.status;
    if (status === 401) {
      AuthService.clearIsImpersonated();
      //@todo tr to refresh token, if ok, re-run response.request
      //in case of error -> redirect to login
      router.push(publicUrls.login);
    }
    if (status === 503) {
      router.push(publicUrls.unavailable);
    }
    if ([404, 500].includes(status)) {
      response.json().then(res => {
        notify({
          type: 'error',
          text: res.message ?? 'Something went wrong',
        });
      });
    }
    return response;
  },
});

return (_ctx: any,_cache: any) => {
  const _component_notifications = _resolveComponent("notifications")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_notifications),
    _createVNode(_component_router_view)
  ], 64))
}
}

})