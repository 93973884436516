import { getStudyDataByUuid, studyType } from '@/services/api.service';
import { IStudy } from '@/types/agency';
import AuthService from '@/services/auth.service';
import { defineStore } from 'pinia';
import useAuthStore from './auth';

export enum SidebarSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export interface GlobalState {
  theme: 'a' | 'b';
  spinner: boolean;
  userdata: {
    hideSwitchHint: boolean;
    a: Record<string, any>;
    b: Record<string, any>;
  };
  important: null | string;
  customReports: Record<string, any>;
  dialogs: Record<string, boolean>;
  reports: Array<string>;
  init?: boolean;
  activepanels: Record<string, Array<string> | string>;
  sidebar: {
    width: number;
    opacity: number;
    size: number;
    style: 'docked' | 'floating';
  };
  map: {
    opacity: number;
    style: string;
    mode3d: boolean;
  };
  infobox: {
    semiOpaque: boolean;
    size: number;
    left: number;
    top: number;
    width: number;
  };
  thematicSlider: {
    left: number;
    bottom: number;
  };
  study: IStudy | null;

  assistance: {
    activeStep: string | null;
    initialData?: Record<string, any>;
  };
}

const DEFAULT_INFOBOX = {
  semiOpaque: false,
  size: 90,
  width: 450,
  left: 50,
  top: 10,
};

const LOCAL_STORAGE_KEYS = [
  'important',
  'dialogs',
  'reports',
  'sidebar',
  'map',
  'infobox',
  'thematicSlider',
  'study',
  'assistance',
  'theme',
  'userdata',
];

const useGlobalStore = defineStore({
  id: 'global',
  getters: {
    studyType: state => (state.study?.type === studyType.STUDYTYPE_NONPROFIT ? 'nonprofit' : 'profit'),
  },
  state: () =>
    ({
      theme: 'a',
      spinner: false,
      userdata: {
        hideSwitchHint: false,
        a: {},
        b: {
          collapsed: false,
        },
      },

      customReports: {
        '1': {},
        '4': {},
      },
      important: null,
      dialogs: {},
      reports: [],
      activepanels: {
        recent: 'Generated Items',
        plotting: ['Center', 'People Plot'],
        shapes: ['Drawing Settings'],
        layers: ['Agency Boundaries'],
      },
      sidebar: {
        width: 550,
        opacity: 100,
        size: 100,
        style: 'docked',
      },
      map: {
        opacity: 100,
        style: 'mapbox://styles/mapbox/streets-v12',
        mode3d: false,
      },
      infobox: { ...DEFAULT_INFOBOX },
      thematicSlider: { left: 50, bottom: 120 },
      study: null,
      assistance: {
        activeStep: null,
      },
    }) as GlobalState,

  actions: {
    closeDialogs() {
      this.dialogs = {};
    },

    async selectStudy(studyUuid: string) {
      const authStore = useAuthStore();
      authStore.studyTokenPending = true;
      this.dialogs.noAgencyAccess = false;

      await AuthService.getStudyToken(studyUuid);

      authStore.isStudyInToken = true;
      authStore.studyTokenPending = false;

      const study = await getStudyDataByUuid(studyUuid);

      if (study && study.agency) {
        this.study = {
          name: study.name,
          type: study.type,
          description: study.description,
          bbox: [study.bboxList[0], study.bboxList[1], study.bboxList[2], study.bboxList[3]],
          uuid: study.uuid,
          agency: study.agency,
          isBase: study.isbasestudy,
        };
      }

      this.dialogs.changeStudy = false;
      await authStore.getCurrentUser();

      if (!authStore.hasPermission('masa')) {
        this.dialogs.noAgencyAccess = true;
      }
    },

    setSidebarWidth(width: SidebarSize) {
      const sizes = {
        SMALL: 370,
        MEDIUM: 550,
        LARGE: 700,
      };
      this.sidebar.width = sizes[width];
    },
    resetInfobox() {
      this.infobox = { ...DEFAULT_INFOBOX };
    },
    resetControlCenter() {
      this.sidebar = {
        ...this.sidebar,
        width: 550,
        opacity: 100,
        size: 100,
        style: 'docked',
      };
    },
    addReportWatch(report: string) {
      this.reports = [...this.reports, report];
    },
    removeReportWatch(reportid: string) {
      if (this.reports.includes(reportid)) {
        this.reports = this.reports.filter(item => item !== reportid);
      }
    },
  },
  persist: {
    paths: LOCAL_STORAGE_KEYS,
  },
});

export { DEFAULT_INFOBOX };
export default useGlobalStore;
