import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createTextVNode(" © Copyright " + _toDisplayString(new Date().getFullYear()) + " ACS Technologies | ", 1),
    _cache[0] || (_cache[0] = _createElementVNode("a", {
      href: "https://help.acst.com/?resourceId=privacy.policy",
      target: "_blank"
    }, "Privacy Policy", -1)),
    _cache[1] || (_cache[1] = _createTextVNode(" | ")),
    _cache[2] || (_cache[2] = _createElementVNode("a", {
      href: "https://help.acst.com/?resourceId=terms",
      target: "_blank"
    }, "Terms of Service", -1))
  ]))
}