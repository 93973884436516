import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-screen d-flex flex-column" }

import AppCopyright from '@/components/general/Copyright.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'LayoutWithCopyright',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    _createVNode(AppCopyright)
  ]))
}
}

})