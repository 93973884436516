export const baseUrl = typeof window !== 'undefined' ? window.origin : process.env.BASE_URL || '';

export const apiUrls = {
  auth: {
    registration: '/api/auth/registration',
    login: '/api/auth/login',
    logout: '/api/auth/logout',
    refresh: '/api/auth/refresh',
    profile: '/api/auth/profile',
    currentUser: '/api/auth/currentUser',
  },
};

export const publicUrls = {
  home: '/',
  error: '/404',
  login: '/login',
  noacl: '/no-permissions-error',
  noagency: '/no-agencies-error',
  postLogin: '/post-login',
  registration: '/registration',
  postLogout: '/post-logout',
  startLogin: '/oauth/start_login',
  startRegistration: '/oauth/start_registration',
  postRegistration: '/post-registration',
  preRegistration: '/start-registration',
  preLogin: '/start-login',
  startLogout: '/oauth/logout',
  unavailable: '/unavailable',
  userApproval: '/approvedeny',
};

export const dashboardUrls = {
  home: 'home',
  map: 'map',
  view: 'views',
  layers: 'overlays',
  shapes: 'draw',
  plotting: 'data',
  demographics: 'reporting-demographics',
  generateReports: 'generate-reports',
  peoplePlot: 'people-plot',
  thematicMap: 'thematic-map',
  neighborCenter: 'neighbor-center',
  opportunityScan: 'opportunity-scan',
  purchaseAddressList: 'purchase-address-list',
  recent: 'history',
  help: 'help',
};
