import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main-screen" }
const _hoisted_2 = { class: "bg-primary d-flex items-center px-2 pt-2 pb-2" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "grid grid-cols-2 login-content" }
const _hoisted_5 = { class: "login-left text-wrap d-flex align-center flex-column gap-4" }
const _hoisted_6 = { class: "d-flex flex-column gap-4 text-wrap" }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]

import { contacts } from '@/constants/web';
import logo from '@/assets/img/mi-logo.png';
import { phone } from '@/utils/common';
import { publicUrls } from '@/constants/urls';
import useGlobalStore from '@/stores/global';
import AuthLayout from './AuthLayout.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props) {

const store = useGlobalStore();
store.closeDialogs();
function logIn() {
  window.location.href = publicUrls.preLogin;
}

function registerIn() {
  window.location.href = publicUrls.preRegistration;
}

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(AuthLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _unref(logo),
            alt: "",
            style: {"max-width":"360px","width":"100%"}
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_v_btn, {
              color: "primary",
              class: "login-button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (logIn()))
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Log in")
              ])),
              _: 1
            }),
            _createVNode(_component_v_btn, {
              color: "primary",
              class: "login-button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (registerIn()))
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Registration")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-h5 font-weight-bold" }, "Engagement Begins Here", -1)),
            _cache[8] || (_cache[8] = _createElementVNode("p", null, "MissionInsite helps you bridge the gap from data to decision.", -1)),
            _cache[9] || (_cache[9] = _createElementVNode("p", null, " With your faith-based nonprofit’s data and current demographics information, you can understand your community and the people you serve. ", -1)),
            _cache[10] || (_cache[10] = _createElementVNode("p", null, [
              _createElementVNode("strong", null, "Log in and begin exploring, interacting and learning.")
            ], -1)),
            _createElementVNode("p", null, [
              _cache[4] || (_cache[4] = _createTextVNode(" Need Help with Registration? Email: ")),
              _createElementVNode("a", {
                href: `mailto:${_unref(contacts).email}`
              }, _toDisplayString(_unref(contacts).email), 9, _hoisted_7),
              _cache[5] || (_cache[5] = _createTextVNode(" or call ")),
              _createElementVNode("a", {
                href: `tel:${_unref(phone)(_unref(contacts).phone.main)}`
              }, _toDisplayString(_unref(contacts).phone.main), 9, _hoisted_8),
              _cache[6] || (_cache[6] = _createTextVNode(". "))
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})