import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loader-wrapper"
}
const _hoisted_2 = { class: "bg-primary d-flex items-center pl-6 pt-2 pb-2" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  class: "px-4 pt-4 pb-10",
  style: {"max-height":"calc(100vh - 116px)","overflow":"auto"}
}
const _hoisted_6 = { class: "container" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "d-flex mb-6" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = ["href"]

import auth from '@/services/auth.service';

import LayoutWithCopyright from '@/components/general/LayoutWithCopyright.vue';
import { ref, watch } from 'vue';
import { contacts } from '@/constants/web';
import AppLoading from '@/components/general/Loading.vue';
import image from '@/assets/img/mi-logo.png';
import { useStorage } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { onMounted } from 'vue';
import { clone } from '@/utils/common';
import { publicUrls } from '@/constants/urls';
import {
  verifyRegistrationCode,
  applyRegistrationCode,
  userProfileUpdate,
  getUserFromToken,
} from '@/services/api.service';
import { computed } from 'vue';
import { notify } from '@kyvg/vue3-notification';
import { locationByCity } from '@/services/api.service';
import { ModelSelect } from 'vue-search-select';
import { sortBy } from 'lodash';


export default /*@__PURE__*/_defineComponent({
  __name: 'Registration',
  setup(__props) {

const DEFAULT_DATA = {
  step: '1',
  agencyCode: '',
  agencyMagicCode: '',
  acceptTerms: false,
  firstname: '',
  lastname: '',
  title: '',
  city: '',
  location: null,
  name: '',
};
const router = useRouter();
const form = useStorage('register', clone(DEFAULT_DATA), localStorage, { mergeDefaults: true });
const loading = ref(false);
const check = ref<any>(null);
const cities = computed(() => check.value?.locationsbycityList?.map((item: any) => item.city));
const locations = computed(() => {
  if (!check.value?.city) return [];
  const city = check.value?.locationsbycityList?.find((item: any) => item.city === check.value.city);
  return sortBy(city?.locationsList, location => location.name);
});

watch(locations, () => {
  if (locations.value.length) {
    check.value.location = locations.value[0]?.locationid;
  }
});

const disabled = computed(() => {
  if (!form.value.acceptTerms) return true;
  if (check.value?.status !== 'ok') return true;
  if (!check.value.location) return true;
  return false;
});

const agencyCheck = (code: string) => {
  loading.value = true;
  return verifyRegistrationCode(code)
    .then(async data => {
      const res = await locationByCity(data.agencyuuid);
      check.value = {
        status: 'ok',
        data,
        locationsbycityList: res.locationsbycityList,
      };
    })
    .catch(error => {
      console.log({ error });
      form.value = clone({ ...form.value, agencyMagicCode: '' });
      check.value = {
        status: 'error',
        data: 'Invalid registration code',
      };
    })
    .finally(() => {
      loading.value = false;
    });
};
const handleAgencyCheck = () => {
  agencyCheck(form.value.agencyCode);
};

const handleRegister = async () => {
  try {
    const userdata = await getUserFromToken();
    let name = `${userdata.firstname} ${userdata.lastname}`;
    await applyRegistrationCode(form.value.agencyCode, check.value.location);
    if (check.value?.data.isuserconfigured === false) {
      await userProfileUpdate({
        firstname: form.value.firstname,
        lastname: form.value.lastname,
        title: form.value.title,
        showwelcomescreen: true,
      });
      name = `${form.value.firstname} ${form.value.lastname}`;
    }
    return (form.value = clone({ ...form.value, name, step: '5' }));
  } catch (error) {
    console.log(error);
    notify({
      type: 'error',
      text: 'Something went wrong',
    });
  }
};

const quickCheck = () => {
  if (check.value?.status === 'error' && form.value.agencyCode.length) return (check.value = null);
};

onMounted(async () => {
  if (router.currentRoute.value.query?.setuser) {
    form.value = clone({ ...DEFAULT_DATA, step: '3' });
  }
  if (router.currentRoute.value.query?.agencyCode) {
    auth.logout(false);
    const agencyMagicCode = String(router.currentRoute.value.query?.agencyCode);
    form.value = clone({ ...DEFAULT_DATA, agencyMagicCode, agencyCode: agencyMagicCode, step: '3' });
    return (window.location.href = publicUrls.preRegistration);
  } else {
    form.value = clone({ ...DEFAULT_DATA, ...form.value, step: '3' });
  }

  if (form.value.step === '3' && form.value.agencyMagicCode.length > 0) {
    agencyCheck(form.value.agencyMagicCode);
  }
});

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!

  return (_openBlock(), _createBlock(LayoutWithCopyright, null, {
    default: _withCtx(() => [
      (_unref(router).currentRoute.value.query?.agencyCode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(AppLoading)
          ]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_RouterLink, { to: "/login" }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: _unref(image),
                      alt: "",
                      style: {"max-width":"360px"}
                    }, null, 8, _hoisted_4)
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_v_card, { class: "bg-grey-lighten-3 mb-8" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, { class: "px-10 py-10" }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createElementVNode("h3", { class: "text-h5" }, "MissionInsite Registration", -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_unref(form).step === '3')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      (!_unref(form).agencyMagicCode?.length)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _cache[9] || (_cache[9] = _createElementVNode("p", { class: "mt-4 mb-4" }, " Instructions: Your agency has been provided an account number. Please type that account number in the 'Agency Account Number' field. Click submit. ", -1)),
                            _createElementVNode("div", _hoisted_8, [
                              _createVNode(_component_v_text_field, {
                                label: "Agency Account Number",
                                modelValue: _unref(form).agencyCode,
                                "onUpdate:modelValue": [
                                  _cache[0] || (_cache[0] = ($event: any) => ((_unref(form).agencyCode) = $event)),
                                  quickCheck
                                ],
                                class: "flex-1",
                                disabled: check.value?.status === 'ok'
                              }, null, 8, ["modelValue", "disabled"])
                            ]),
                            (check.value?.status === 'error')
                              ? (_openBlock(), _createBlock(_component_v_alert, {
                                  key: 0,
                                  type: "warning",
                                  prominent: "",
                                  border: "top",
                                  color: "red-darken-3",
                                  class: "mb-6"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(check.value.data), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (check.value?.status !== 'ok')
                              ? (_openBlock(), _createBlock(_component_v_btn, {
                                  key: 1,
                                  class: "mb-4",
                                  color: "primary",
                                  disabled: !_unref(form).agencyCode,
                                  loading: loading.value,
                                  onClick: handleAgencyCheck,
                                  template: ""
                                }, {
                                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                                    _createTextVNode("Submit")
                                  ])),
                                  _: 1
                                }, 8, ["disabled", "loading"]))
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true),
                      (_unref(form).step === '3' && _unref(form).agencyMagicCode.length > 0 && loading.value)
                        ? (_openBlock(), _createBlock(AppLoading, { key: 1 }))
                        : _createCommentVNode("", true),
                      (check.value?.status === 'ok' && check.value.data.isuserconfigured === false)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _cache[10] || (_cache[10] = _createElementVNode("p", { class: "mt-4 mb-4" }, "Now, let's collect some additional information about you.", -1)),
                            _createVNode(_component_v_text_field, {
                              modelValue: _unref(form).firstname,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(form).firstname) = $event)),
                              label: "First Name"
                            }, null, 8, ["modelValue"]),
                            _createVNode(_component_v_text_field, {
                              modelValue: _unref(form).lastname,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(form).lastname) = $event)),
                              label: "Last Name"
                            }, null, 8, ["modelValue"]),
                            _createVNode(_component_v_text_field, {
                              modelValue: _unref(form).title,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(form).title) = $event)),
                              label: "Title"
                            }, null, 8, ["modelValue"])
                          ], 64))
                        : _createCommentVNode("", true),
                      (check.value?.status === 'ok' && check.value.data.isstudyincluded === false)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                            _cache[11] || (_cache[11] = _createElementVNode("p", { class: "mt-4 mb-4" }, " We need a bit more information. You can use the selection box below to select the study you work with. ", -1)),
                            _createVNode(_unref(ModelSelect), {
                              class: "mb-4",
                              modelValue: check.value.city,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((check.value.city) = $event)),
                              placeholder: "Select your city...",
                              options: cities.value.map((item) => ({ value: item, text: item }))
                            }, null, 8, ["modelValue", "options"]),
                            _createVNode(_unref(ModelSelect), {
                              class: "mb-4",
                              isDisabled: !check.value.city,
                              modelValue: check.value.location,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((check.value.location) = $event)),
                              placeholder: "Select your location...",
                              options: locations.value.map((item) => ({ value: item.locationid, text: item.name }))
                            }, null, 8, ["isDisabled", "modelValue", "options"])
                          ], 64))
                        : _createCommentVNode("", true),
                      (check.value?.status === 'ok')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                            _createVNode(_component_v_alert, { type: "info" }, {
                              default: _withCtx(() => [
                                _cache[14] || (_cache[14] = _createElementVNode("p", null, " Authorized Organization User: Licensed access to this site is for the exclusive use of this Organization and exclusively for its mission planning. Personal use or use for a business or another organization other than the licensing agency is expressly forbidden. User also agrees not to share his/her username or password with anyone else. Failure to abide by these terms will be a violation of this license agreement and may result in the revocation of access to this user. ", -1)),
                                _createElementVNode("p", null, [
                                  _cache[12] || (_cache[12] = _createTextVNode(" Organization Location Advisory The first step after completing the registration process is to login to your local organization. The current location was provided by your agency and in some cases the address information is incomplete and does not allow an accurate placement of your organization. (This is more likely to happen for locations in rural areas.) There is no cause for alarm. It is an easy process to move your organization’s geographic location on the map once we receive additional information from you. Normally, this change can be made within 48 hours. Simply send an email to your Agency Administrator or to MissionInsite at ")),
                                  _createElementVNode("a", {
                                    class: "underline text-white",
                                    href: `mailto:${_unref(contacts).email}`
                                  }, _toDisplayString(_unref(contacts).email), 9, _hoisted_9),
                                  _cache[13] || (_cache[13] = _createTextVNode(" with additional information on your organization’s location. Please note: Though your organization may be temporarily in the wrong location, the demographic information is accurate for the area. Individual organization locations are an added overlay and do not influence how the underlying demographic data is stored and reported. "))
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_checkbox, {
                              label: "Accept Terms",
                              modelValue: _unref(form).acceptTerms,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(form).acceptTerms) = $event))
                            }, null, 8, ["modelValue"]),
                            _createVNode(_component_v_btn, {
                              class: "mt-4",
                              color: "primary",
                              disabled: disabled.value,
                              loading: check.value?.status === 'ok' && loading.value,
                              onClick: handleRegister
                            }, {
                              default: _withCtx(() => _cache[15] || (_cache[15] = [
                                _createTextVNode("Register")
                              ])),
                              _: 1
                            }, 8, ["disabled", "loading"])
                          ], 64))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (_unref(form).step === '5')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _cache[16] || (_cache[16] = _createElementVNode("h3", { class: "text-h6 mb-4" }, "Registration Complete", -1)),
                      _createElementVNode("p", null, " Thank you for registering, " + _toDisplayString(_unref(form).name) + "! An email has been sent to the Agency Administrator requesting that they activate your account. ", 1),
                      _cache[17] || (_cache[17] = _createElementVNode("p", null, [
                        _createElementVNode("small", null, " You will receive an email once your account has been activated and you are able to use MissionInsite. Until that time, you may continue to login to the application and see the current status of your account, or access alternate Agencies normally if you are registered with multiple Agencies. ")
                      ], -1)),
                      _cache[18] || (_cache[18] = _createElementVNode("p", null, [
                        _createElementVNode("small", null, " Thank you for signing up for MissionInsite! ")
                      ], -1)),
                      _createElementVNode("p", null, [
                        _createElementVNode("a", {
                          taret: "_blank",
                          class: "underline",
                          href: _unref(publicUrls).login
                        }, "Go To Application", 8, _hoisted_11)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ], 64))
    ]),
    _: 1
  }))
}
}

})